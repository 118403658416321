<template>
    <div>   
            <br>
            <b-card
                header="選擇主辦單位"
                border-variant="light"
                header-border-variant="light"
                header-bg-variant="light"
                align="center"
                class="card-3"            
                >
            <b-card-body style="width:980px;" class="overflow-auto" id="style-3">
            <b-card-text> 
                <div class="text-center">                                                   
                                <a-radio-group v-model="selecitem" @change="onChange">
                                    <div>
                                    <b-form-group label="" v-slot="{ ariaDescribedby }">
                                        <span class="h5 mb-2">新建單位 <b-icon icon="file-earmark-plus-fill"></b-icon></span>                                        
                                        &emsp;
                                        <b-form-radio-group
                                            id="btn-radios-2"
                                            v-model="selected"
                                            :options="options"
                                            :aria-describedby="ariaDescribedby"
                                            @change="handleRadioChange"
                                            button-variant="outline-primary"
                                            size="lg"
                                            name="radio-btn-outline"
                                            buttons
                                        ></b-form-radio-group>
                                    </b-form-group>
                                    <a-alert v-if="selecitem!==null" style="font-weight: bold;font-size: 18px;" :message="'您選擇的主辦單位為 : '+orgdata[selecitem].name" type="info" show-icon />
                                    <hr class="hr-dotted">
                                    </div>
                                    <div class="overflow-auto text-left" style="height: 450px;padding:0.4rem;border: 0.5px solid #aae2f8;border-radius:10px;">
                                        <div v-for="(result,i) in orgdata" :key="i" :class="i == selecitem? 'cssSelected':'cssUnSelected'">                                   
                                            <p v-if="result.type!=='admin'">
                                                <a-radio-button :value="i" :disabled="result.state=='N'">
                                                選擇 <b-icon icon="pencil-square" scale="1" variant="primary"></b-icon>
                                                </a-radio-button>
                                            &emsp;
                                            {{result.name}}  <a-avatar icon="user" :src="result.prourl" /> <span :style="{'color': result.state==='N' ? 'red' : 'blue'}" v-text="rogname(result.type,result.state)" /> 
                                            </p>                                                                      
                                        </div>
                                    </div>
                                    <br>
                                </a-radio-group>                           
               
                </div>

                <b-modal ref="my-lines"  size="lg" centered @hide="onHide" id="LineLg" title="" hide-header hide-footer>
                    <div>
                        <Morganizer v-show="selected=='org'" @done="cdone"  @close="close" ref="chon1"/>   
                        <Town v-show="selected=='town'" @done="cdone"  @close="close" ref="chon2"/>   
                        <County v-show="selected=='county'" @done="cdone" @close="close" ref="chon3"/>     
                    </div>
                </b-modal>  
                
            </b-card-text>
            </b-card-body>
            </b-card>

            <br>
            
    </div>
</template>
<script>
import Morganizer from './type/Morganizer'
import Town from './type/Town'
import County from './type/County'
import {mapState,mapActions,mapMutations} from 'vuex';
export default {
    name: 'Organizer',
    components : {
        Morganizer,
        Town,
        County,
    },
    props:{
          page:{
              type:Number
          },
          cobj:{ 
            type:Object
          },           
        },    
    data(){
        return {
            disableds:false,
            selected: '',
            selecitem: null,
            orgdata:[],
            options: [
                    { text: '據點團體組織', value: 'org' },
                    { text: '局處/鄉鎮公所', value: 'town' },
                    //{ text: 'Radio 3 (disabled)', value: 'radio3', disabled: true },
                    { text: '縣市政府', value: 'county' }
                    ]            
        }
    },
    watch: {
        cobj() {
            if (this.cobj!==undefined) {
                if(this.cobj.id>0){
                    this.onupdate(this.cobj.orgid)
                }
            }
        },      
    },    
    computed:{
        ...mapState('account',['status','user']),
        ...mapState("makeorg", ["datas","defaultval"]),        
    },
    methods :{
      ...mapActions('makeorg',['readorg']),
      ...mapMutations("makeorg", ["setMIDs"]),      
        rogname(val1,val2){
            let rs =''
            if(val1==="county"){
                rs = "層級 : 縣市政府 / 狀態 : " 
                rs += val2==='N'? '尚未審核通過':'審核通過'
            }else if(val1==="town"){
                rs = "層級 : 局處/鄉鎮公所 / 狀態 : " 
                rs += val2==='N'? '尚未審核通過':'審核通過'                
            }else if(val1==="org"){
                rs = "層級 : 據點團體組織 / 狀態 : " 
                rs += val2==='N'? '尚未審核通過':'審核通過'                
            }
            return rs
        },
    
        cdone(rs){
            this.orgdata.push(rs.form);
            this.close();
            //this.orgdata.push(rs.form);
            //this.$emit('done',rs)
        },

        getdata(){
          let rs = this.$refs.chon1.getdata()
          return rs
        },

        close(){            
            this.selected=''
            this.$refs["my-lines"].hide(); 
        },

        async onupdate(orgid){
            const self = this;
            setTimeout(() => {
                        for (var i in self.orgdata) {                        
                        if(self.orgdata[i].id==orgid){
                             self.selecitem = parseInt(i);
                             self.onChange();
                            break;                      
                        }
                    } 
                    }, "1000");
        },

        onChange() {
            let rs={
                pages: 1,
                state:true,
                data : this.orgdata[this.selecitem]
            }            
            this.$emit('done',rs)
        },
        
        async upload(){
            let id={id:this.user.data.user.id}
            if(this.defaultval.id!==null){
                this.onupdate(this.defaultval.id);
                this.orgdata = this.datas;
                this.setMIDs(this.defaultval.id)
            }else{
                this.orgdata = await this.readorg(id);
            }
        },

        onHide(evt) {
          if(evt.trigger === "backdrop"){
              evt.preventDefault();
          }
        }, 
        handleRadioChange(value) {
            if(value!==''){
                this.$refs["my-lines"].show();
            }else{
                this.$refs["my-lines"].hide(); 
            }
        }               
    },
    mounted () {

    },
    created () {
        this.upload();
    }
    
}
</script>
<style scoped>
#style-3::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

#style-3::-webkit-scrollbar
{
	width: 6px;
	background-color: #F5F5F5;
}

#style-3::-webkit-scrollbar-thumb
{
	background-color: #000000;
}

#style-3{
    text-align: left;
}

.ddiv{
    padding:7px;
    border-radius: 17px;
    margin: 5px auto;
    box-shadow: 1px 1px 15px -5px rgb(46, 44, 44);
	transition: all .3s ease;
}
.card-3 {
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}

.hr-dotted {
    border: 0;
    background-color: #F5F5F5;
    border-top: 3px dotted #a2a9b6;
    opacity:0.6;
}

.hr-dotted2 {
    border: 1;
    background-color: #F5F5F5;
    border-top: 2px dashed #a2a9b6;
    opacity:0.3;
}
.cssSelected {
  font-size:16px;
  font-weight:bold;
  padding-top:0.28rem;
  background: linear-gradient(-45deg, #ffffff, #9bdbf5, #bfe7f7);
}
.cssUnSelected {
  font-size:15px;
  padding-top:0.28rem;
  background: linear-gradient(-45deg, #ffffff, #d8edf5, #ffffff);
}
</style>