<template>
    <div class="ddic">
        <b-icon @click="close" class="closebtn" icon="x-circle" scale="2" variant="danger"></b-icon>
        <div class="text-center">   
        <br v-show="errors!==''"><br v-if="formdata">         
        <a-alert v-show="errors!==''" :message="errors" :type="mtyps" show-icon /> 
        <a-alert v-if="formdata" message="資料填妥, 確認無誤進行下一步" type="success" />        
        <p class="h4"><b-icon-people-fill></b-icon-people-fill> 建立新主辦單位 (縣市政府) </p>                              
        <a-upload
            name="imgfile"
            list-type="picture"
            class="avatar-uploader"
            method='post'
            :show-upload-list="false"
            :headers="headers"
            :action="uploadurl"
            :before-upload="beforeUpload"
            @change="handleChange" 
        >
        <b-avatar :src="form.url" variant="primary" text="" size="8rem" class="align-baseline"></b-avatar>
        </a-upload>
        </div>    
            <b-form @reset="onReset">
                
                <p class="h6"><b-icon-vinyl-fill></b-icon-vinyl-fill> 關於</p> 
                <hr style="height:1px; border-width:0; color: #3D7878; background-color: #3D7878;">
                <b-form-group id="input-group-1"
                :description="result? tmpname+' 該名稱已存在, 請使用其它名稱':''"
                style="width:350px" label="主辦單位名稱*" label-for="input-1">
                    <b-form-input
                        id="input-1"
                        v-model="form.name"
                        type="text"
                        placeholder="輸入主辦單位名稱 " 
                        :state="nameState" 
                        @change="checkAccount"
                        autocomplete="off"                        
                        required
                    ></b-form-input>
                </b-form-group>

                <b-form-group id="input-group-2" style="width:80%" label="主辦單位簡介*  " label-for="input-2">
                    <b-form-textarea
                        id="input-2"
                        v-model="form.desc"
                        type="text"
                        placeholder="輸入主辦單位簡介 "
                        rows="8"
                        autocomplete="off"
                        :state="descState"                        
                        required
                    ></b-form-textarea>
                </b-form-group>      
                
                <p class="h6"><b-icon-grid-fill></b-icon-grid-fill> 聯絡資料</p>   
                <hr style="height:1px; border-width:0; color: #3D7878; background-color: #3D7878;">                
                <b-form-group id="input-group-3" style="width:80%" label="主辦單位 Email*" label-for="input-3">
                    <b-form-input
                        id="input-3"
                        v-model="form.email"
                        type="email"
                        placeholder="輸入主辦單位 Email "
                        autocomplete="off"
                        :state="emailState" 
                        required
                    ></b-form-input>
                </b-form-group>     
                
                <b-form-group id="input-group-4" style="width:250px" label="主辦單位電話號碼*  " label-for="input-4">
                    <b-form-input
                        id="input-4"
                        v-model="form.hphone"
                        type="number"
                        placeholder="輸入主辦單位,電話號碼 "
                        autocomplete="off"
                        :state="hphoneState" 
                        required
                    ></b-form-input>
                </b-form-group> 

                <b-form-group id="input-group-5" style="width:250px" label="主辦單位手機號碼*  " label-for="input-5">
                    <b-form-input
                        id="input-5"
                        v-model="form.mphone"
                        type="number"
                        placeholder="輸入主辦單位, 手機 "
                        autocomplete="off"
                        :state="mphoneState"                         
                        required
                    ></b-form-input>
                </b-form-group>
                <Addresscom @toaddress="toaddress"  />
                <b-form-group id="input-group-6" style="width:80%" label="主辦單位聯絡地址*  " label-for="input-6">
                    <b-form-input
                        id="input-6"
                        v-model="form.address"
                        type="text"
                        placeholder="輸入主辦單位聯絡地址"
                        autocomplete="off"
                        :state="addressState"                            
                        required
                    ></b-form-input>
                </b-form-group>                                   
 
                <p class="h6"><b-icon-tree-fill></b-icon-tree-fill> 媒體連結</p> 
                <hr style="height:1px; border-width:0; color: #3D7878; background-color: #3D7878;"> 
                <b-form-group id="input-group-7" style="width:80%" label="官方網站  " label-for="input-7">
                    <b-form-input
                        id="input-7"
                        v-model="form.sinkurl"
                        type="url"
                        placeholder="輸入官方網站"
                        autocomplete="off"
                        required
                    ></b-form-input>
                </b-form-group>   

                <b-form-group id="input-group-8" style="width:80%" label="Facebook  " label-for="input-8">
                    <b-form-input
                        id="input-8"
                        v-model="form.finkurl"
                        type="url"
                        placeholder="輸入Facebook"
                        autocomplete="off"
                        required
                    ></b-form-input>
                </b-form-group>    

                <b-form-group id="input-group-9" style="width:80%" label="Instagram  " label-for="input-9">
                    <b-form-input
                        id="input-9"
                        v-model="form.iinkurl"
                        type="url"
                        placeholder="輸入Instagram"
                        autocomplete="off"
                        required
                    ></b-form-input>
                </b-form-group>  

                <b-form-group id="input-group-10" style="width:80%" label="Youtube  " label-for="input-10">
                    <b-form-input
                        id="input-10"
                        v-model="form.yinkurl"
                        type="url"
                        placeholder="輸入Youtube"
                        autocomplete="off"
                        required
                    ></b-form-input>
                </b-form-group>                  
                <a-button class="bton" size="large" @click="onSubmit" type="primary" shape="round" :disabled="!formdata" block :loading="iconLoading">建立新主辦單位(縣市政府)</a-button>               
            </b-form>


        <b-modal ref="my-loading" id="modal-2" title="Loading" @hide="onHide" centered hide-footer hide-header>
          <div class="text-center">
              <b-spinner  variant="primary" label="Text Centered"></b-spinner>
              <strong >Loading....</strong>
          </div>
        </b-modal> 

    </div>
</template>
<script>
import {mapState,mapActions} from 'vuex';
import Addresscom from "../../components/Addresslist.vue"
import { makorgService } from "../../_services/";
export default {
    name: 'County',
    components: {
        Addresscom,
    },    
    props:{
          page:{
              type:Number
          },
        },    
    data(){
        return {
            disableds:false, 
            errors:'',
            mtyps:'error',
            iconLoading: false,
            result: false,
            uploadurl:process.env.VUE_APP_URL +'user/image', 
            tmpname:'',
            form: {
                 url : '',   
                 name : '',
                 desc: '',
                 email: '',
                 hphone:'',
                 mphone:'',
                 address:'',
                 sinkurl:'',
                 finkurl:'',
                 iinkurl:'',
                 yinkurl:'',          
                },              
                
        }
    },
    computed:{
      ...mapState('account',['status','user']),
      ...mapState('makeorg',['getrs']),
      headers(){   
        return{                    
          "X-Requested-With":null,
          "Authorization": "Bearer "+this.status.token,
        }
      },

      formdata(){    
          if(this.form.name!=='' && this.form.desc!=='' && this.form.email!=='' && this.form.hphone!=='' && this.form.mphone!=='' && this.form.address!==''){            
            return true
          }else{
            return false
          }
      },
      nameState() {
        return this.form.name.length > 0 ? true : false
      },  
      
      descState() {
        return this.form.desc.length > 0 ? true : false
      },      

      emailState() {
        return this.form.email.length > 0 ? true : false
      },      
      
      hphoneState() {
        return this.form.hphone.length > 0 ? true : false
      },        

      mphoneState() {
        return this.form.mphone.length > 0 ? true : false
      },        
      addressState() {
        return this.form.address.length > 0 ? true : false
      },

    },    
    methods :{
      ...mapActions('makeorg',['insertorg']),
      async onSubmit(event) {
            event.preventDefault()
            this.iconLoading = true 

            let county={
                    name : this.form.name,
                    desc : this.form.desc,
                    type : 'county',
                    ntype : 'admin',                    
                    address : this.form.address,
                    email : this.form.email,
                    hphone : this.form.hphone,
                    mphone : this.form.mphone,
                    titUrl : '',
                    proUrl : this.form.url,
                    sinkurl : this.form.sinkurl,
                    finkurl : this.form.finkurl,
                    iinkurl : this.form.iinkurl,
                    yinkurl : this.form.yinkurl,
                    acts_uslist: [
                        {uid:this.user.data.user.id }
                        ]
                } 
                let Rsults =await this.insertorg(county);                

                setTimeout(() => {
                this.iconLoading = false
                if(Rsults.state ==='N'){
                        let rs={
                            pages: 1,
                            state:true,
                            form: Rsults,
                        }
                        this.empty()   
                        this.$emit('done',rs) 
                        this.$emit('close')  
                }                           
                }, 1300);
        },

        empty(){
                 this.form.url = ''   
                 this.form.name = ''
                 this.form.desc= ''
                 this.form.email= ''
                 this.form.hphone=''
                 this.form.mphone=''
                 this.form.address=''
                 this.form.sinkurl=''
                 this.form.finkurl=''
                 this.form.iinkurl=''
                 this.form.yinkurl=''
        },

        getdata(){
            return this.form
        },

        toaddress(val,city,village,town){
            this.form.address = val
            console.log(city,village,town)            
        },        
        
        onReset(event) {
                event.preventDefault()
                this.$nextTick(() => {

                })
            },          

        async handleChange({file}) {                 
                if(file.status ==='done'){ 
                    this.form.url = file.response.Location
                    this.$refs['my-loading'].hide()
                }
        },  
        
        onHide(evt) {
            if(evt.trigger === "backdrop"){
                evt.preventDefault();
                this.handleBackdrop();
            }
        }, 
        
        close(){
            this.$emit('close')  
        },

        beforeUpload(file) {
                this.errors=''
                //this.$emit('upmessage',null)
                const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
                const isLt2M = file.size / 1024 / 1024 < 2;

                return new Promise((resolve, reject) => {
                  if(!isJpgOrPng) {
                    //this.$message.error('您的檔案必須是 JPG or PNG!');
                    this.errors = '您的檔案必須是 JPG or PNG!'
                    this.mtyps = "error"
                    //this.$emit('upmessage','您的檔案必須是 JPG or PNG!')
                  reject(file);
                  } else if(!isLt2M) {
                    //this.$message.error('您的檔案必須是小於 2MB!');
                    this.errors = '您的檔案必須是小於 2MB!'
                    this.mtyps = "error"
                    //this.$emit('upmessage','您的檔案必須是小於 2MB!')
                  reject(file);
                  }else {
                  this.$refs['my-loading'].show()
                  resolve(file);
                  }
              });
        }, 

        async checkAccount() {
            let tmp = this.form.name
            this.form.name = tmp.toString().trim()
            if (this.form.name) {   
                this.tmpname = this.form.name             
                this.result = await makorgService
                .readorgItem(this.form.name)
                .then(rs => {
                    return rs;
                });
                if (this.result>0) {
                    this.form.name = "";
                }
            }

        },          

    },
  

    
}
</script>
<style scoped>
.avatar-uploader {
    position: relative;
    max-width: 150px;
    margin: auto;
    margin-bottom: 20px;}
.ddic{
    padding:20px;
    border-radius: 15px;
    margin: 50px auto;
    box-shadow: 1px 1px 15px -5px rgb(172, 5, 5);
	transition: all .3s ease;
}
.closebtn{
    position: relative;
    top: 0;
    right: 0;
    float: right;
    cursor: pointer; 
}
</style>